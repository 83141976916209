import React from 'react'
import { Button, Row, Table, Typography } from 'antd'
import PropTypes from 'prop-types'
import { CSVLink } from 'react-csv'

import { calculateColumnsWidth } from './DynamicColumnHelper'

import './ReportTable.css'

const { Title } = Typography

const ReportTable = ({ associateListResponse, dataSource, type }) => {
  const maxWidthPerCell = 200
  const tableHeight = 578

  const contactColumns = [
    {
      title: 'Agent Name',
      label: 'Agent Name',
      dataIndex: 'agentName',
      key: 'agentName'
    },
    {
      title: 'Total Online Time (Hours)',
      label: 'Total Online Time',
      dataIndex: 'totalOnlineTime',
      key: 'totalOnlineTime'
    },
    {
      title: 'Total Idle %',
      label: 'Total Idle %',
      dataIndex: 'idlePercentage',
      key: 'idlePercentage'
    },
    {
      title: 'Total Handle Time (Hours)',
      label: 'Total Handle Time',
      dataIndex: 'totalHandleTime',
      key: 'totalHandleTime'
    },
    {
      title: 'Total Idle Time (Hours)',
      label: 'Total Idle Time',
      dataIndex: 'totalIdleTime',
      key: 'totalIdleTime'
    },
    {
      title: 'Customer Available %',
      label: 'Customer Available %',
      dataIndex: 'customerAvailableInPercent',
      key: 'customerAvailableInPercent'
    },
    {
      title: 'Inbound Call Productivity Ratio',
      label: 'Inbound Call Productivity Ratio',
      dataIndex: 'productivityRatio',
      key: 'productivityRatio'
    },
    {
      title: 'Inbound Call Avg Handle Time (HH:MM:SS)',
      label: 'Inbound Call Avg Handle Time (HH:MM:SS)',
      dataIndex: 'inboundCallAverageHandleTime',
      key: 'inboundCallAverageHandleTime'
    },
    {
      title: 'Inbound Call Handled',
      label: 'Inbound Call Handled',
      dataIndex: 'inboundAgentHandle',
      key: 'inboundAgentHandle'
    },
    {
      title: 'Outbound Call Handled',
      label: 'Outbound Call Handled',
      dataIndex: 'outboundCallsHandled',
      key: 'outboundCallsHandled'
    },
    {
      title: 'First Call Resolution %',
      label: 'First Call Resolution %',
      dataIndex: 'repeatCallPercentage',
      key: 'repeatCallPercentage'
    },
    // {
    //   title: 'Repeat Calls (within 7days)',
    //   label: 'Repeat Calls (within 7days)',
    //   dataIndex: 'repeatCallCount',
    //   key: 'repeatCallCount'
    // },
    {
      title: 'Total Talk Time',
      label: 'Total Talk Time',
      dataIndex: 'totalTalk',
      key: 'totalTalk'
    },
    {
      title: 'Outbound Call Avg Handle Time (HH:MM:SS)',
      label: 'Outbound Call Avg Handle Time (HH:MM:SS)',
      dataIndex: 'outboundCallAverageHandleTime',
      key: 'outboundCallAverageHandleTime'
    }
    // {
    //   title: 'Inbound Calls per hour',
    //   label: 'Inbound Calls per hour',
    //   dataIndex: 'inboundCallsPerHour',
    //   key: 'inboundCallsPerHour'
    // }
  ]

  const contactColumns2 = [
    {
      title: 'Agent Name',
      label: 'Agent Name',
      dataIndex: 'agentName',
      key: 'agentName',
      width: 200
    },
    {
      title: 'Total Online Time (hours)',
      label: 'Total Online Time (hours)',
      dataIndex: 'totalOnlineTimeView',
      key: 'totalOnlineTimeView',
      width: 200
    },
    {
      title: 'Voicemail Count',
      label: 'Voicemail Count',
      dataIndex: 'totalVoicemailCount',
      key: 'totalVoicemailCount',
      width: 200
    },
    {
      title: 'Inbounds Alerted',
      label: 'Inbounds Alerted',
      dataIndex: 'totalInboundAlerted',
      key: 'totalInboundAlerted',
      width: 200
    },
    {
      title: 'Inbound Answer Rate %',
      label: 'Inbound Answer Rate %',
      dataIndex: 'inboundAnswerRatePercent',
      key: 'inboundAnswerRatePercent',
      width: 200
    },
    {
      title: 'Inbound Calls Handled',
      label: 'Inbound Calls Handled',
      dataIndex: 'totalInboundCallsHandled',
      key: 'totalInboundCallsHandled',
      width: 200
    },
    {
      title: 'Inbound Call Avg Handle Time (HH:MM:SS)',
      label: 'Inbound Call Avg Handle Time (HH:MM:SS)',
      dataIndex: 'claimsInboundPhoneCallsAverageHandleTime',
      key: 'claimsInboundPhoneCallsAverageHandleTime',
      width: 200
    },
    {
      title: 'Outbound Call Handled',
      label: 'Outbound Call Handled',
      dataIndex: 'claimsOutboundPhonePerformanceCallsHandled',
      key: 'claimsOutboundPhonePerformanceCallsHandled',
      width: 200
    },
    // {
    //   title: 'Repeat Calls (within 7 days)',
    //   label: 'Repeat Calls (within 7 days)',
    //   dataIndex: 'totalRepeatCallsCount',
    //   key: 'totalRepeatCallsCount',
    //   width: 200
    // },
    {
      title: 'Outbound Call Avg Handle Time (HH:MM:SS)',
      label: 'Outbound Call Avg Handle Time (HH:MM:SS)',
      dataIndex: 'claimsOutboundPhoneCallsAverageHandleTime',
      key: 'claimsOutboundPhoneCallsAverageHandleTime',
      width: 200
    },
    {
      title: 'Total Talk Time',
      label: 'Total Talk Time',
      dataIndex: 'totalTalk',
      key: 'totalTalk'
    },
    {
      title: 'Total Unavailable Time (hours)',
      label: 'Total Unavailable Time (hours)',
      dataIndex: 'totalUnavailableTime',
      key: 'totalUnavailableTime',
      width: 200
    },
    {
      title: 'Total Unavailable Time %',
      label: 'Total Unavailable Time %',
      dataIndex: 'totalUnavailableTimePercent',
      key: 'totalUnavailableTimePercent',
      width: 200
    }
  ]

  const dataTable = dataSource && calculateColumnsWidth(contactColumns, dataSource, maxWidthPerCell)

  return (
    <>
      <Row className="report-by-type">
        {type === 2 && associateListResponse && (
          <table>
            <tbody>
              <tr>
                <td className="text">Total Online Time (hours):</td>
                <td className="text">
                  <Title level={5}>{associateListResponse.totalOnlineTime}</Title>
                </td>
              </tr>
              <tr>
                <td className="text">Inbound Calls Handled:</td>
                <td className="text">
                  <Title level={5}>{associateListResponse.totalInboundCallsHandled}</Title>
                </td>
              </tr>
              <tr>
                <td className="text">Inbounds Alerted:</td>
                <td className="text">
                  <Title level={5}>{associateListResponse.totalInboundAlerted}</Title>
                </td>
              </tr>
              <tr>
                <td className="text">Inbound Answer Rate %:</td>
                <td className="text">
                  <Title level={5}>{associateListResponse.inboundAnswerRateInPercent}</Title>
                </td>
              </tr>
              <tr>
                <td className="text">Inbound Call Avg Handle Time (HH:MM:SS):</td>
                <td className="text">
                  <Title level={5}>{associateListResponse.inboundCallAvgHandleTime}</Title>
                </td>
              </tr>
              <tr>
                <td className="text">Total Unavailable Time:</td>
                <td className="text">
                  <Title level={5}>{associateListResponse.totalUnavailableTime}</Title>
                </td>
              </tr>
              <tr>
                <td className="text">Total Unavailable Time %:</td>
                <td className="text">
                  <Title level={5}>{associateListResponse.totalUnavailableTimePercent}</Title>
                </td>
              </tr>
              <tr>
                <td className="text">Total Voicemail Count:</td>
                <td className="text">
                  <Title level={5}>{associateListResponse.totalVoicemailCount}</Title>
                </td>
              </tr>
              {/* <tr>
                <td className="text">Repeat Calls (within 7 days):</td>
                <td className="text">
                  <Title level={5}>{associateListResponse.totalRepeatCallsCount}</Title>
                </td>
              </tr> */}
              <tr>
                <td className="text">Outbound Calls Handled:</td>
                <td className="text">
                  <Title level={5}>{associateListResponse.outboundCallsHandled}</Title>
                </td>
              </tr>
              <tr>
                <td className="text">Total Aggregate Total Talk Time:</td>
                <td className="text">
                  <Title level={5}>{associateListResponse.totalAggregateTotalTalkTime}</Title>
                </td>
              </tr>
              <tr>
                <td className="text">Outbound Call Avg Handle Time (HH:MM:SS):</td>
                <td className="text">
                  <Title level={5}>{associateListResponse.outboundCallAverageHandleTime}</Title>
                </td>
              </tr>
            </tbody>
          </table>
        )}
        {type === 1 && associateListResponse && (
          <table>
            <tbody>
              <tr>
                <td className="text">Total Idle Time (Hours):</td>
                <td className="text">
                  <Title level={5}>{associateListResponse.aggregateTotalIdleTime}</Title>
                </td>
              </tr>
              <tr>
                <td className="text">Total Idle Time %:</td>
                <td className="text">
                  <Title level={5}>{associateListResponse.totalIdlePercentage}</Title>
                </td>
              </tr>
              <tr>
                <td className="text">Total Online Time (Hours):</td>
                <td className="text">
                  <Title level={5}>{associateListResponse.aggregateTotalOnlineTime}</Title>
                </td>
              </tr>
              <tr>
                <td className="text">Total Handle Time (Hours):</td>
                <td className="text">
                  <Title level={5}>{associateListResponse.aggregateTotalHandleTime}</Title>
                </td>
              </tr>
              <tr>
                <td className="text">Customer Available %:</td>
                <td className="text">
                  <Title level={5}>{associateListResponse.customerAvailablePercentage}</Title>
                </td>
              </tr>
              <tr>
                <td className="text">Outbound Call Avg Handle Time (HH:MM:SS):</td>
                <td className="text">
                  <Title level={5}>{associateListResponse.outBoundCallAvgHandleTime}</Title>
                </td>
              </tr>
              <tr>
                <td className="text">Inbound Call Productivity Ratio:</td>
                <td className="text">
                  <Title level={5}>{associateListResponse.productivityRatio}</Title>
                </td>
              </tr>
              <tr>
                <td className="text">Inbound Call Avg Handle Time (HH:MM:SS ):</td>
                <td className="text">
                  <Title level={5}>{associateListResponse.inBoundCallAvgHandleTime}</Title>
                </td>
              </tr>
              <tr>
                <td className="text">Inbound Call Handled:</td>
                <td className="text">
                  <Title level={5}>{associateListResponse.totalInboundCallsHandled}</Title>
                </td>
              </tr>
              {/* <tr>
                <td className="text">Inbound Calls per Hour:</td>
                <td className="text">
                  <Title level={5}>{associateListResponse.inBoundCallsPerHour}</Title>
                </td>
              </tr> */}
              <tr>
                <td className="text">Outbound Calls Handled:</td>
                <td className="text">
                  <Title level={5}>{associateListResponse.totalOutboundCallsHandled}</Title>
                </td>
              </tr>
              {/* <tr>
                <td className="text">Repeat Calls (within 7days):</td>
                <td className="text">
                  <Title level={5}>{associateListResponse.aggregateRepeatCallCount}</Title>
                </td>
              </tr> */}
              <tr>
                <td className="text">Total Aggregate Total Talk Time:</td>
                <td className="text">
                  <Title level={5}>{associateListResponse.totalAggregateTotalTalkTime}</Title>
                </td>
              </tr>
              <tr>
                <td className="text">First Call Resolution %:</td>
                <td className="text">
                  <Title level={5}>{associateListResponse.aggregateRepeatCallPercentage}</Title>
                </td>
              </tr>
            </tbody>
          </table>
        )}
        <Row style={{ width: '100%', display: 'block' }}>
          <Button className="btn" disabled={dataSource.length === 0}>
            <CSVLink data={dataSource} filename="report" headers={ type === 1 ? contactColumns : contactColumns2}>
              <img alt="export" src="https://assets.cure.com/abc/img/excel.png" />
            </CSVLink>
          </Button>
        </Row>
        <Table
          columns={ type === 1 ? contactColumns : contactColumns2}
          dataSource={dataSource}
          height={tableHeight}
          pagination={{ pageSize: 50 }}
          scroll={{ x: dataTable.tableWidth, y: tableHeight }}
        />
      </Row>
    </>
  )
}

ReportTable.propTypes = {
  dataSource: PropTypes.array
}

export default ReportTable
