import React from 'react'
import { Navigate } from 'react-router-dom'

const PrivateRoute = ({ children }) => {
  const isLogin = JSON.parse(localStorage.getItem('crc-key'))

  return isLogin ? children : <Navigate to="/login" />
}

export default PrivateRoute
