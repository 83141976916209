import React, { useState } from 'react'
import { Card, Radio, Row, Typography } from 'antd'

import './Home.css'
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'

const { Title } = Typography
const Home = () => {
  const [value, setValue] = useState(0)

  const onChange = e => {
    setValue(e.target.value)
    if (e.target.value === 1) {
      window.location = '/report1'
    } else if (e.target.value === 2) {
      window.location = '/report2'
    }
  }

  return (
    <>
      <Header selectedOpton={0} />
      <Row className="page-content bg-white">
        <Card bordered={false} className="input-card">
          <Title level={4} className="bold">
            Select a report
          </Title>
          <Radio.Group onChange={onChange} value={value}>
            <Radio value={1}>
              <Title level={4}>Agent Summary Report</Title>
            </Radio>
            <Radio value={2}>
              <Title level={4}>Claims Phone Reporting</Title>{' '}
            </Radio>
          </Radio.Group>
        </Card>
      </Row>
      <Footer />
    </>
  )
}

export default Home
