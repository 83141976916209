import React, { useState } from 'react'
import { Button, Card, Input, Row, Space } from 'antd'

import './Login.css'

const Login = () => {
  const [password, setPassword] = useState('')

  const handleSubmit = () => {
    if (password === 'Cure2022!') {
      localStorage.setItem('crc-key', JSON.stringify(password))
      window.location = '/home'
    }
  }

  return (
    <>
      <Row className="page-content bg-white">
        <Card bordered={false} className="input-card" style={{ textAlign: 'center' }}>
          <img
            src="https://assets.cure.com/img/main-site/LogoBlue-2022.png"
            height="auto"
            width="100%"
            alt="logo"
            style={{ maxWidth: 550, minWidth: 150 }}
          />
          <Row style={{ marginTop: '25vh', textAlign: 'center', display: 'block' }}>
            <Space direction="vertical" style={{ width: '100%' }}>
              <Input disabled size="large" placeholder="User name" value="CRC" style={{ maxWidth: 550, minWidth: 150 }} />
              <Input.Password
                size="large"
                placeholder="Password"
                value={password}
                style={{ maxWidth: 550, minWidth: 150 }}
                onChange={event => setPassword(event.target.value)}
              />
              <Button type="primary" className="submit-btn" style={{ marginTop: 18, height: 36, width: 200 }} onClick={handleSubmit}>
                Submit
              </Button>
            </Space>
          </Row>
        </Card>
      </Row>
    </>
  )
}

export default Login
