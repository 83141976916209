import { Layout } from 'antd'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import './App.css'
import Home from './pages/Home/Home'
import AgentSummaryReport from './pages/AgentSummaryReport/AgentSummaryReport'
import ClaimsPhoneReporting from './pages/ClaimsPhoneReporting/ClaimsPhoneReporting'
import PrivateRoute from './components/PrivateRoute/PrivateRoute'
import Login from './pages/Login/Login'

import 'antd/dist/antd.min.css'

const App = () => {
  return (
    <BrowserRouter>
      <Layout className="cure-main-container">
        <Routes>
          <Route element={<Login />} exact path="/" />
          <Route element={<Login />} exact path="/login" />
          <Route
            path="/home"
            element={
              <PrivateRoute>
                <Home />
              </PrivateRoute>
            }
          />
          <Route
            path="/report1"
            element={
              <PrivateRoute>
                <AgentSummaryReport />
              </PrivateRoute>
            }
          />
          <Route
            path="/report2"
            element={
              <PrivateRoute>
                <ClaimsPhoneReporting />
              </PrivateRoute>
            }
          />
        </Routes>
      </Layout>
    </BrowserRouter>
  )
}

export default App
