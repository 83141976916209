import axios from 'axios'

const { REACT_APP_API_URL } = process.env

export const getAllDates = async () => {
  const config = {
    method: 'POST',
    url: `${REACT_APP_API_URL}/fetchAllDates`,
    headers: {
      'Content-Type': 'application/json'
    }
  }

  let apiResponse
  try {
    apiResponse = await axios(config)
  } catch (error) {
    console.log(error)
  }
  return apiResponse
}

export const getAllAssociates = async () => {
  const config = {
    method: 'POST',
    url: `${REACT_APP_API_URL}/fetchAllUniqueAgentNames`,
    headers: {
      'Content-Type': 'application/json'
    }
  }

  let apiResponse
  try {
    apiResponse = await axios(config)
  } catch (error) {
    console.log(error)
  }
  return apiResponse
}

export const fetchReportByAssociates = async associates => {
  const config = {
    method: 'POST',
    url: `${REACT_APP_API_URL}/fetchReportOneByAgentNames`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: JSON.stringify(associates)
  }

  let apiResponse
  try {
    apiResponse = await axios(config)
  } catch (error) {
    console.log(error)
  }
  return apiResponse
}

export const fetchASRByDate = async (startDate, endDate) => {
  const data = new FormData()
  data.append('firstStartDate', startDate)
  data.append('secondStartDate', endDate)

  const config = {
    method: 'POST',
    url: `${REACT_APP_API_URL}/fetchReportOneByDuration`,
    headers: {
      'Content-Type': 'application/json'
    },
    data
  }

  let apiResponse
  try {
    apiResponse = await axios(config)
  } catch (error) {
    console.log(error)
  }
  return apiResponse
}

export const fetchReportByDateAndAssociates = async (selectedDates, associates) => {
  const { formattedStartDate, formattedEndDate } = selectedDates
  const data = new FormData()
  data.append('firstStartDate', formattedStartDate)
  data.append('secondStartDate', formattedEndDate)
  data.append('associates', JSON.stringify(associates))

  const config = {
    method: 'POST',
    url: `${REACT_APP_API_URL}/fetchReportOneByAgentNames`,
    headers: {
      'Content-Type': 'application/json'
    },
    data
  }

  let apiResponse
  try {
    apiResponse = await axios(config)
  } catch (error) {
    console.log(error)
  }
  return apiResponse
}

export const fetchReportOneFilteredByAgentNamesOrDuration = async data => {
  const { firstStartDate, secondStartDate, agentNames, calculateRepeatCall } = data
  let requestData = {}

  if (firstStartDate && secondStartDate && !agentNames) {
    requestData = JSON.stringify({
      firstStartDate,
      secondStartDate,
      calculateRepeatCall
    })
  } else if (!firstStartDate && !secondStartDate && !agentNames) {
    requestData = JSON.stringify({
      agentNames
    })
  } else {
    requestData = JSON.stringify({
      firstStartDate,
      secondStartDate,
      calculateRepeatCall,
      agentNames
    })
  }

  const config = {
    method: 'POST',
    url: `${REACT_APP_API_URL}/fetchReportOneFilteredByAgentNamesOrDuration`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: requestData
  }

  let apiResponse
  try {
    apiResponse = await axios(config)
  } catch (error) {
    console.log(error)
  }
  return apiResponse
}

export const getReport2AllAssociates = async () => {
  const config = {
    method: 'POST',
    url: `${REACT_APP_API_URL}/report2/fetchAllClaimsUniqueAgentNames`,
    headers: {
      'Content-Type': 'application/json'
    }
  }

  let apiResponse
  try {
    apiResponse = await axios(config)
  } catch (error) {
    console.log(error)
  }
  return apiResponse
}

export const getReport2AllDates = async () => {
  const config = {
    method: 'POST',
    url: `${REACT_APP_API_URL}/report2/fetchAllClaimsDates`,
    headers: {
      'Content-Type': 'application/json'
    }
  }

  let apiResponse
  try {
    apiResponse = await axios(config)
  } catch (error) {
    console.log(error)
  }
  return apiResponse
}


export const fetchReportTwoFilteredByAgentNamesOrDuration = async data => {
  const { firstStartDate, secondStartDate, agentNames } = data
  let requestData = {}

  if (firstStartDate && secondStartDate && !agentNames) {
    requestData = JSON.stringify({
      firstStartDate,
      secondStartDate
    })
  } else if (!firstStartDate && !secondStartDate && !agentNames) {
    requestData = JSON.stringify({
      agentNames
    })
  } else {
    requestData = JSON.stringify({
      firstStartDate,
      secondStartDate,
      agentNames
    })
  }

  const config = {
    method: 'POST',
    url: `${REACT_APP_API_URL}/report2/fetchReportClaimOneFilteredByAgentNamesOrDuration`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: requestData
  }

  let apiResponse
  try {
    apiResponse = await axios(config)
  } catch (error) {
    console.log(error)
  }
  return apiResponse
}
