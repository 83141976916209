import React from 'react'
import { Col, Layout, Row, Typography } from 'antd'
import PropTypes from 'prop-types'

import './Header.css'
const { Title } = Typography

const Header = ({ selectedOption = 0 }) => {

  const handleClick = () => {
    const isLogin = JSON.parse(localStorage.getItem('crc-key'))
    if (isLogin) {
      window.location = '/home'
    } else {
      window.location = '/'
    }
  }

  return (
    <>
      <Layout.Header className="menu-nav">
        <Row>
          <Col lg={4} md={5} sm={2} xl={3} xs={2}>
            <div className="logo-holder">
              <a onClick={handleClick}>
                <img alt="menu-nav-logo" src="https://assets.cure.com/img/main-site/cure-logo.png" />
              </a>
            </div>
          </Col>
          <Col lg={8} md={5} sm={2} xl={8} xs={2} className="title">
            {selectedOption === 0 && (
              <Title style={{ paddingTop: 32 }} level={5}>
                Welcome - CURE CRC Reporting
              </Title>
            )}
            {selectedOption === 1 && (
              <Title style={{ paddingTop: 32 }} level={5}>
                CRC - Agent Status Summary Report
              </Title>
            )}
            {selectedOption === 2 && (
              <Title style={{ paddingTop: 32 }} level={5}>
                CRC - Claims Phone Reporting
              </Title>
            )}
          </Col>
        </Row>
      </Layout.Header>
      <Layout.Header className="menu-nav-mobile">
        <Row>
          <Col lg={4} md={5} sm={5} xl={3} xs={10}>
            <div className="logo-holder">
              <a href="/">
                <img alt="nav-mobile-logo" src="https://assets.cure.com/img/main-site/cure-logo.png" />
              </a>
            </div>
          </Col>
          <Col lg={12} md={14} sm={10} xl={10} xs={14} className="mobile-title">
            {selectedOption === 0 && <Title level={5}>Welcome - CURE CRC Reporting</Title>}
            {selectedOption === 1 && <Title level={5}>CRC - Agent Status Summary Report</Title>}
            {selectedOption === 2 && <Title level={5}>CRC - Claims Phone Reporting</Title>}
          </Col>
        </Row>
      </Layout.Header>
    </>
  )
}

Header.propTypes = {
  handleFeedbackClick: PropTypes.func,
  hideGlobalHeader: PropTypes.bool,
  maintainenceMessage: PropTypes.string,
  isMaintainenceLoading: PropTypes.bool
}

Header.defaultProps = {
  hideGlobalHeader: true,
  maintainenceMessage: '',
  isMaintainenceLoading: true
}

export default Header
