import React from 'react'
import { Row, Typography } from 'antd'

import './Footer.css'

const { Text } = Typography

const Footer = () => {
  return (
    <footer>
      <Row className="footer">
        <Text className="title" type="secondary">
          Copyright© 2022 CURE. All rights reserved.
        </Text>
      </Row>
    </footer>
  )
}

Footer.propTypes = {}

export default Footer
