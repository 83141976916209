import React, { useEffect, useState } from 'react'
import { LoadingOutlined, SyncOutlined } from '@ant-design/icons'
import { Button, Card, DatePicker, Row, Space, Spin, Tooltip } from 'antd'
import moment from 'moment'
import { MultiSelect } from 'react-multi-select-component'

import './ClaimsPhoneReporting.css'
import Header from '../../components/Header/Header'
import ReportTable from '../../components/ReportTable/ReportTable'
import { getReport2AllDates, getReport2AllAssociates, fetchReportTwoFilteredByAgentNamesOrDuration } from '../../services/Api'
import Footer from '../../components/Footer/Footer'

const { RangePicker } = DatePicker

const ClaimsPhoneReporting = () => {
  const [datesList, setDatesList] = useState([])
  const [minDate, setMinDate] = useState()
  const [maxDate, setMaxDate] = useState()
  const [statusReports, setStatusReports] = useState([])
  const [selectedAssociation, setSelectedAssociation] = useState([])
  const [associateList, setAssociateList] = useState([])
  const [dateRange, changeDateRange] = useState(null)
  const [associateListResponse, setAssociateListResponse] = useState(null)
  const [selectedDates, setSelectedDates] = useState()
  const [dataIsLoading, setDataIsLoading] = useState(false)

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 32
      }}
      spin
    />
  )

  const fetchDates = async () => {
    const { data } = await getReport2AllDates()
    const momentDates = data.map(d => moment(d, 'MM-DD-YYYY'))
    const maxDate = moment.max(momentDates)
    const minDate = moment.min(momentDates)
    setMinDate(minDate.format('YYYY-MM-DD'))
    const updatedMaxDate = maxDate.add(1, 'days').format('YYYY-MM-DD')
    setMaxDate(updatedMaxDate)
    setDatesList(data)
  }

  useEffect(() => {
    if (datesList && datesList.length === 0) {
      fetchDates()
    }
  }, [datesList])

  const fetchAssociates = async () => {
    const { data } = await getReport2AllAssociates()
    const list = data.map(item => ({
      label: item,
      value: item
    }))
    setAssociateList(list)
  }

  useEffect(() => {
    if (associateList && associateList.length === 0) {
      fetchAssociates()
    }
  }, [associateList])

  const handleChange = async dates => {
    const [startDate, endDate] = dates
    const formattedStartDate = startDate ? startDate.format('MM-DD-YYYY') : startDate
    const formattedEndDate = endDate ? endDate.format('MM-DD-YYYY') : endDate
    setSelectedDates({
      formattedStartDate,
      formattedEndDate
    })
  }

  const handleAssociateChange = async values => {
    setSelectedAssociation(values)
  }

  const returnMomentDateRange = (start, finish) => {
    return [moment(start, 'YYYY-MM-DD'), moment(finish, 'YYYY-MM-DD')]
  }

  const onDateRangeChange = dateRange => {
    if (dateRange) {
      changeDateRange(returnMomentDateRange(dateRange[0], dateRange[1]))
    } else {
      changeDateRange(null)
    }
  }

  const handleSubmit = async () => {
    setDataIsLoading(true)
    let request = {}
    if (selectedDates && selectedDates.formattedStartDate && selectedDates.formattedEndDate) {
      request = {
        firstStartDate: selectedDates.formattedStartDate,
        secondStartDate: selectedDates.formattedEndDate
      }
    }
    if (selectedAssociation && selectedAssociation.length > 0) {
      request = {
        ...request,
        agentNames: selectedAssociation.map(item => item.value)
      }
    }
    if (Object.keys(request).length > 0) {
      const response = await fetchReportTwoFilteredByAgentNamesOrDuration(request)
      const { data } = response
      const { aggregateReport, reportTwoMetricModelList } = data

      setAssociateListResponse(aggregateReport)
      setStatusReports(reportTwoMetricModelList)
    } else {
      setStatusReports([])
      setAssociateListResponse()
    }
    setDataIsLoading(false)
  }

  const refreshData = () => {
    fetchAssociates()
    fetchDates()
  }

  const customValueRenderer = (selected, _options) => {
    return selected.length ? selected.map(s => s.label).join(', ') : ' '
  }

  const filterOptions = (options, filter) => {
    if (!filter) {
      return options
    }
    const re = new RegExp('^' + filter, 'i') //new RegExp(filter, "i");
    return options.filter(({ value }) => value && value.match(re))
  }

  return (
    <>
      <Header selectedOption={2} />
      <Row className="page-content">
        <Card bordered={false} className="input-card">
          {dataIsLoading && <Spin indicator={antIcon} className="antd-loadicon" />}
          <Space>
            <MultiSelect
              options={associateList}
              value={selectedAssociation}
              onChange={handleAssociateChange}
              valueRenderer={customValueRenderer}
              filterOptions={filterOptions}
            />
            <RangePicker
              allowClear={true}
              style={{ minWidth: 320, marginLeft: 18, height: 36 }}
              onCalendarChange={handleChange}
              value={dateRange}
              onChange={onDateRangeChange}
              disabledDate={d => !d || d.isAfter(maxDate) || d.isSameOrBefore(minDate)}
            />
            <Tooltip placement="bottom" title={'Refresh Agentname and Date Filter'} color="#294c7c">
              <Button icon={<SyncOutlined />} style={{ marginLeft: 8, height: 36 }} onClick={() => refreshData()} />
            </Tooltip>
            <Button type="primary" className="submit-btn" style={{ marginLeft: 8, height: 36 }} onClick={handleSubmit}>
              Submit
            </Button>
          </Space>
          <br />
          <br />
          <br />
          <ReportTable type={2} dataSource={statusReports} associateListResponse={associateListResponse} />
        </Card>
      </Row>
      <Footer />
    </>
  )
}

export default ClaimsPhoneReporting
